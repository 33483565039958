import { useParams } from "react-router-dom";
import LineActivity from "./LineActivity";
import { useQuery } from "@tanstack/react-query";
import { getLineDetail } from "../../api/line";
import { Suspense } from "react";
import LineDetail from "./LineDetail";
import { Container, Stack } from "@mui/material";

const LineDetailPage = () => {
  let { lineID } = useParams();

  const { data: line } = useQuery({
    queryKey: ["line", lineID],
    queryFn: async () => {
      return getLineDetail(lineID);
    },
    suspense: true,
  });

  const lineData = line?.data;
  const lineProgresses = line?.data?.listProgress || [];
  console.log("lineProgresses", lineProgresses);

  return (
    <Container maxWidth="xl">
      <Stack spacing={4} className="right-content w-100">
        {lineData && <LineDetail line={lineData} />}
        <LineActivity datas={lineProgresses} />
      </Stack>
    </Container>
  );
};

const withSuspense = (Component) => (props) => {
  return (
    <Suspense fallback={"loading!"}>
      <Component {...props} />
    </Suspense>
  );
};
export default withSuspense(LineDetailPage);
