import { Card, CardContent, CardHeader, Grid, styled, Typography } from "@mui/material";
import { periods } from "../../constants";
import LineDetailCarousel from "./LineDetailCarousel";
import LineDetailsSummary from "./LineDetailsSummary";

const LineDetail = ({ line }) => {
  const images = line.images || [imgUrl, imgUrl2, imgUrl3, imgUrl4];
  line.images = images;
  return (
    <Card>
      <CardHeader title="Line Detail" />
      <CardContent>
        <Grid sx={{ p: 0 }} container spacing={3}>
          <Grid
            item
            xs={12}
            md={6}
            lg={5}
            xl={5}
            maxWidth={"520px"}
            sx={{
              maxWidth: "520px",
            }}
          >
            <LineDetailCarousel data={line} images={images.map(({ url }) => url)} />
          </Grid>
          <Grid item xs={12} md={6} lg={7} xl={7}>
            <LineDetailsSummary data={line} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const imgUrl = "https://api-prod-minimal-v610.pages.dev/assets/images/m-product/product-1.webp";
const imgUrl2 = "https://api-prod-minimal-v610.pages.dev/assets/images/m-product/product-2.webp";
const imgUrl3 = "https://api-prod-minimal-v610.pages.dev/assets/images/m-product/product-3.webp";
const imgUrl4 = "https://api-prod-minimal-v610.pages.dev/assets/images/m-product/product-4.webp";

export default LineDetail;
