import { merge } from "lodash";
import Paper from "./Paper";
import Table from "./Table";
import Dialog from "./Dialog";
import Input from "./Input";
import ControlLabel from "./ControlLabel";
import Autocomplete from "./Autocomplete";
import StaticDateRangePicker from "./StaticDateRangePicker";
import Button from "./Button";
import Card from "./Card";
import Tabs from "./Tabs";
import Container from "./Container";
// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return merge(
    Table(theme),
    // Paper(theme),
    Dialog(theme),
    Input(theme),
    ControlLabel(theme),
    Autocomplete(theme),
    StaticDateRangePicker(theme),
    Button(theme),
    Card(theme),
    Tabs(theme),
    // Container(theme)
  );
}
