import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Modal,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
} from "@mui/material/";
import { getApiURL } from "../../../common/utils/domainUtil";
// import "./PopupAddStyle.css";
import useAuth from "../../../hooks/useAuth";
import { Close } from "@mui/icons-material";

const RegisterNodePopup = ({ onClose, handleRegister, open }) => {
  const token = "ABCXYZ";
  const email = "nguyenan@gmail.com";
  const lineID = "2024_06_00001";
  const seq = "11";

  const apiBladeRegister = "public/line/register";
  const apiURLBladeRegister = getApiURL(apiBladeRegister);
  const { user } = useAuth();

  const [userData, setUserData] = useState({
    email: email,
    token: token,
    lineID: lineID,
    seq: seq,
    depositStatus: "Y",
  });

  return (
    <Dialog open={open} maxWidth="lg">
      <DialogTitle>
        Đăng ký
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Bạn muốn đăng ký?
          </Typography>
          {/* <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            {user?.email} <br />
            Nhấn vào <Link onClick={handleRegister}>đây</Link> để đăng ký
          </Typography> */}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Huỷ
        </Button>
        <Button variant="contained" onClick={handleRegister}>
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterNodePopup;
