import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { getUserDetail } from "../../api/user";
import { useQuery } from "@tanstack/react-query";
import { MyContext } from "../../App";
import { emphasize, Breadcrumbs, Button, Chip, styled, Grid, Paper, Container, InputAdornment } from "@mui/material/";
import { Home } from "@mui/icons-material";

import { FaCloudUploadAlt } from "react-icons/fa";
import { GrBladesHorizontal } from "react-icons/gr";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdBrandingWatermark, MdGridView, MdOutlinePlayCircle } from "react-icons/md";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { RhfAutocomplete, RhfSelect, RhfUploadMultipleFile } from "../../components/form";
import RhfTextField, { RhfNumber } from "../../components/form/RhfTextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { createLine } from "../../api/line";
import { toast } from "react-toastify";
import { lineReq, periodCounts, periods, PERIODS, registType } from "../../constants";
import { getBlades, getBladeUnitByBladeCD } from "../../api/blade";
import { RhfDatePicker } from "../../components/form/RhfDatePicker";

// Breadcrum code
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
})

const UserProfile = () => {

    // Check login
    // const navigate = useNavigate();
    // const token = localStorage.getItem("jwtToken");
    // if (token == null || token == undefined) navigate("/login");

    // const [userData, setUserData] = useState([]);
    const [searchData, setSearchData] = useState({
        email: localStorage.getItem("emailOrUsername"),
        token: '',
    });

    const onSubmit = async (data) => {
        // alert(JSON.stringify(data));
        try {
          data.email = localStorage.getItem("email");
          const res = await createLine(data);
          toast.success("Create success");

          window.location.href = "/line-list";
        } catch (error) {
          console.error(error);
          toast.error("Create fail");
        }
    };

    const lineValidationSchema = yup.object({
        period: yup.string().required("Required"),
        periodCnt: yup.string().required("Required"),
        // lineReq: yup.string().required("Required"),
        initPrice: yup.number().required("Required"),
    });
    const methods = useForm({
        resolver: yupResolver(lineValidationSchema),
    });
    const { handleSubmit, reset, setValue, watch } = methods;

    const context = useContext(MyContext);

    useEffect(() => {
        context.setIsHideSidebarAndHeader(false);

        window.scrollTo(0, 0);
    })

    const { data: userData } = useQuery({
        queryKey: ["userData"],
        queryFn: async () => {
            return getUserDetail(searchData);
        },
    });
    const userInfo = userData?.data || [];

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = getUserDetail(searchData);
                // setUserData(response);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    return <>

        return (
        <>
            <Container className="right-content w-100">
                <Paper sx={{ p: 2, display: "flex" }}>
                    <h5 className="mb-0">
                        <GrBladesHorizontal /> Profile
                    </h5>
                    <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
                        <StyledBreadcrumb component="a" href="#" label="Dashboard" icon={<Home fontSize="small" />} />
                        <StyledBreadcrumb component="a" href="#" label="Products" />
                        <StyledBreadcrumb label="Profile" />
                    </Breadcrumbs>
                </Paper>

                <h5 className="mt-4 mb-4">
                    <IoIosInformationCircleOutline /> User Information
                </h5>

                <div className="card productDetailsSection">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="sliderWrapper pt-3 pb-3 pl-4 pr-4">
                                <div className="item">
                                    <img src="/upload/images/avatar-1.jpg" className="w-100" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="sliderWrapper pt-3 pb-3 pl-4 pr-4">

                                <h4>{userInfo.lastname} {userInfo.firstname}</h4>

                                <div className="productInfo mt-3">
                                    <div className="row">
                                        <div className="col-sm-4 d-flex align-items-center">
                                            <span className="icon"><MdBrandingWatermark/></span>
                                            <span className="name">Email</span>
                                        </div>

                                        <div className="col-sm-8">
                                            : <span>{userInfo.email}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 d-flex align-items-center">
                                            <span className="icon"><MdBrandingWatermark/></span>
                                            <span className="name">Điện thoại</span>
                                        </div>

                                        <div className="col-sm-8">
                                            : <span>{userInfo.phone}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 d-flex align-items-center">
                                            <span className="icon"><MdBrandingWatermark/></span>
                                            <span className="name">Địa chỉ nhận hàng</span>
                                        </div>

                                        <div className="col-sm-8">
                                            : <span>{userInfo.addressShip}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FormProvider {...methods}>
                    <Paper component="form" sx={{ mt: 3, px: 2, py: 3 }} className="form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} wrap="wrap">
                            <Grid item xs={12}>
                                <RhfUploadMultipleFile shouldPreview={true} name="image" label={"Image"} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RhfTextField name="addressShip" label="Address Ship" rows={1} value={userInfo.addressShip} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RhfTextField name="phone" label="phone" rows={1} />
                            </Grid>
                            <Grid item xs={12} >
                                <RhfTextField name="note" label="Note" rows={2} />
                            </Grid>
                        </Grid>
                        <Button sx={{ mt: 3 }} type="submit" variant="contained">
                            <FaCloudUploadAlt /> &nbsp; PUBLISH AND VIEW
                        </Button>
                    </Paper>
                </FormProvider>
            </Container>
        </>
        );
    </>
}

export default UserProfile;
