import {
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import RegisterNodePopup from "./components/RegisterNodePopup";
import { useState } from "react";
import { api } from "../../api";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { LineRow } from "./LineRow";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.dark,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const LineTable = ({ rows = [] }) => {
  const [currentNode, setCurrentNode] = useState(null);
  const registerNewNode = (data) => {
    setShowModalPopupAdd(true);
    setCurrentNode(data);
  };
  const { user } = useAuth();
  const navigate = useNavigate();
  const handleRegister = async (event) => {
    try {
      // if (currentNode.deposit || currentNode.deposit >= 2000)
      const response = api.post("public/line/register", {
        email: user.email,
        bladeUnitID: currentNode.id,
        seq: currentNode.seq,
        deposit: currentNode.deposit,
        depositStatus: false,
        token: "ABCXYZ",
      });
      await toast.promise(response, {
        pending: "Promise is pending",
        success: "Đăng ký thành công",
        error: "Đăng ký thất bại",
      });

      navigate(`/line/${currentNode.lineId}`);

      setShowModalPopupAdd(false);
    } catch (error) {
      console.error(error);
    }
  };
  const [showModalPopupAdd, setShowModalPopupAdd] = useState(false);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell width={"200px"}>
              <Typography variant="h6" sx={{ color: "primary.main" }}>
                Sản phẩm
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="h6" sx={{ color: "primary.main" }}>
                Thông tin
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows?.map((row) => (
            <LineRow key={row.id} row={row} registerNewNode={registerNewNode} />
          ))}
          {!rows ||
            (rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  Không có sản phẩm
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <RegisterNodePopup
        onClose={() => setShowModalPopupAdd(false)}
        open={showModalPopupAdd}
        handleRegister={() => {
          handleRegister();
        }}
        // lineID={lineID}
        // token={token}
        // email={email}
      />
    </TableContainer>
  );
};

export { LineTable };
