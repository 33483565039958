import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MyContext } from "../../App";
import {
  Box,
  Button, FormControl,
  InputLabel,
  MenuItem, Select,
  Breadcrumbs
} from "@mui/material/";
import { Home, ExpandMore } from "@mui/icons-material";

import StyledBreadcrumb from "../../components/Breadcrumb";
import { LineTable } from "./LineTable";
import { useQuery } from "@tanstack/react-query";
import { getLines } from "../../api/line";
import { getBrands } from "../../api/brand";
import useAuth from "../../hooks/useAuth";

const Line = () => {

  const [brandVal, setBrandVal] = useState(null);
  const [brandSelectData, setBrandSelectData] = useState([]);
  // const [lineProData, setLineProData] = useState(null);

  const context = useContext(MyContext);
  const { isAuthenticated } = useAuth();


  useEffect(() => {
    // Hide sidebar
    context.setIsHideSidebarAndHeader(false);
    window.scrollTo(0, 0);
  });

  const { data, isLoading } = useQuery({
    queryKey: ["lineList", brandVal],
    queryFn: async () => {
      return await getLines({
        brandCD: brandVal
      });
    },
  });

  const { data: dataBrand } = useQuery({
    queryKey: ["brandList"],
    queryFn: async () => {
      return getBrands();
    },
  });

  const lines = data?.data || [];
  const brands = dataBrand?.data || [];


  // Nếu brand có sub brand, setSubBranchVal
  const handleClickBrand = async (event) => {
    try {
      setBrandVal(event.target.value);
    } catch (error) {
      console.error(error);
    }
  };

  // Popup
  const [showModalPopupAdd, setShowModalPopupAdd] = useState(false);
  // const [lineID, setLineID] = useState(null);
  const openPopupAdd = () => {
    setShowModalPopupAdd(!showModalPopupAdd);
    // setLineID(lineID);
  };


  return (
    <>
      <section className="right-content w-100">
        <div className="card shadow border-0 w-100 flex-row p-4">
          <h5 className="mb-0">Line List</h5>
          <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
            <StyledBreadcrumb component="a" href="#" label="Dashboard" icon={<Home fontSize="small" />} />
            <StyledBreadcrumb label="Lines" deleteIcon={<ExpandMore />} />
          </Breadcrumbs>
        </div>

        <div className="card shadow border-0 p-3 mt-4">
          <div className="row cardFilters mt-3 mb-3">
            <div className="col-md-2">
              <div className="logoutBox" onClick={openPopupAdd}>
                <Link to={"/line-create"}>
                  <Button variant="contained" disabled={!isAuthenticated}>Tạo line</Button>
                </Link>
              </div>
            </div>
            <div className="offset-md-8 col-md-2">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                  <Select
                    // size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={brandVal}
                    label="BRAND"
                    onChange={handleClickBrand}
                  >
                    {brands?.map((item) => (
                      <MenuItem key={item.brandCD} value={item.brandCD}>{item.brandName}</MenuItem>
                    ))}
                    {brandSelectData && brandSelectData.length > 0 ? (
                      brandSelectData.map((item) => <MenuItem key={item.brandCD} value={item.brandCD}>{item.brandName}</MenuItem>)
                    ) : (
                      <MenuItem value={"XXX"}>NONE</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>

          <LineTable rows={lines} />
        </div>
      </section>
    </>
  );
};

export default Line;
