import { useMediaQuery, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { Controller, useFormContext } from "react-hook-form";
import { DEFAULT_DATE_FORMAT } from "../../constants";

const RhfDatePicker = ({ name, label, isOnModal = false, format = DEFAULT_DATE_FORMAT, disabled, ...rest }) => {
  const { control } = useFormContext();
  const theme = useTheme();
  const downXL = useMediaQuery(theme.breakpoints.down("xl"));

  const size = downXL ? "small" : "medium";
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        // if (field.value && typeof field.value === "string") {
        //   field.value = moment(field.value);
        // }
        if (field.value && typeof field.value === "string") {
          field.value = moment(field.value);
        } else if (field.value instanceof Date) {
          // If it's a Date object, you can also convert it to moment
          field.value = moment(field.value);
        }

        return (
          <DatePicker
            disabled={disabled}
            label={label}
            {...field}
            format={format}
            slotProps={{
              textField: {
                fullWidth: true,
                error: Boolean(error),
                helperText: error?.message
              },

              popper: isOnModal
                ? {
                    disablePortal: false,
                    popperOptions: {
                      strategy: "fixed"
                    }
                  }
                : {},
              actionBar: {
                actions: ["clear"]
              }
            }}
            {...rest}
          />
        );
      }}
    />
  );
};
export { RhfDatePicker };
