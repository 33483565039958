import {
  Avatar,
  Badge,
  Box,
  Card,
  Chip,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { AttachMoney, CheckCircle, FiberManualRecord, FlagOutlined, Start, TrendingDown } from "@mui/icons-material";
import { useMemo } from "react";
import useAuth from "../../hooks/useAuth";
import { addPeriod } from "../../utils/period";

const currencyFormat = (number) => {
  if (number === null || number === undefined) return "_";
  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  });
  return formatter.format(number);
};
const MAX_ROWS = 12;
const LineRow = ({ row, registerNewNode }) => {
  const { user, isAuthenticated } = useAuth();
  const email = user?.email;
  const isRegisted = useMemo(() => {
    return row.listProgress.some((item) => item.userEmail == email);
  }, [row.listProgress]);
  return (
    <TableRow key={row.name}>
      <TableCell>
        <Box className="imgProductArea">
          <Link to={`/line/${row.id}`}>
            <img
              src="/upload/images/product/takku-1.jpg"
              style={{
                width: "100%",
              }}
              className="imgProduct"
            />
          </Link>
          <div className="progress mt-2">
            <div className="progress-bar" style={{ width: "70%" }}></div>
          </div>
          <h6 className="textProduct mt-2">{row.bladeUnitID}</h6>
        </Box>
      </TableCell>

      <TableCell>
        <Grid container direction={"column"} gap={3}>
          <Grid item>
            <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
              <InfoItem icon={<Start />} label={"Khởi đầu"} value={currencyFormat(row.initPrice)} />
              <InfoItem icon={<AttachMoney />} label={"Đặt cọc"} value={currencyFormat(row.deposit)} />
              <InfoItem icon={<TrendingDown />} label={"Khấu hao"} value={currencyFormat(row.depreciation)} />
              <InfoItem icon={<FlagOutlined />} label={"Kết thúc"} value={currencyFormat(row.endPrice)} />
            </Stack>
          </Grid>
          <Grid item container spacing={1} xs wrap="nowrap">
            {Array(MAX_ROWS)
              .fill(0)
              .map((i, index) => {
                const item = row.listProgress[index];
                const haveItem = !!item?.userEmail;
                const value = haveItem ? currencyFormat(row.initPrice - row.depreciation * index) : 0;
                return (
                  <Grid item xs>
                    <Stack
                      sx={{
                        position: "relative",
                        height: "100%",
                      }}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      {/* <FiberManualRecord /> */}
                      <Box
                        sx={{
                          zIndex: 10,
                          p: 1,
                          backgroundColor: "common.white",
                        }}
                      >
                        {haveItem ? (
                          <Box
                            sx={{
                              p: 0.5,
                              backgroundColor: "primary.lighter",
                              color: "primary.main",
                              borderRadius: "11px",
                              // zIndex: 10
                            }}
                          >
                            <Typography variant="caption">{value}</Typography>
                          </Box>
                        ) : (
                          <FiberManualRecord fontSize="small" sx={{ color: "grey.500" }} />
                        )}
                      </Box>
                      {index !== 0 && (
                        <Box
                          sx={{
                            height: "2px",
                            width: "calc(100%)",
                            position: "absolute",
                            top: "50%",
                            backgroundColor: "primary.main",
                            left: `calc(-50%)`,
                            // transform: "translateX(50%)",
                          }}
                        ></Box>
                      )}
                    </Stack>
                  </Grid>
                );
              })}
          </Grid>

          <Grid item container spacing={1} xs={1} wrap="wrap">
            {row.listProgress.map((i, index) => {
              const isEmpty = !i.userEmail;
              const isLastItem = index === row.listProgress.length - 1;

              let renderItem;
              if (isEmpty) {
                renderItem = (
                  <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                      p: 1,
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderStyle: "dashed",
                      //   backgroundColor: isRegisted ? "grey.200" : "white",
                      flexDirection: "column",
                      ...(isLastItem && {
                        borderColor: "primary.main",
                        borderWidth: 2,
                      }),
                    }}
                  >
                    <IconButton
                      // disabled={isRegisted}
                      onClick={() => {
                        registerNewNode({
                          id: row.bladeUnitID,
                          seq: i.seq,
                          deposit: row.deposit,
                          lineId: row.id,
                        });
                      }}
                      disabled={!isAuthenticated}
                    >
                      <AddIcon />
                    </IconButton>
                  </Card>
                );
              } else {
                renderItem = (
                  <Tooltip
                    title={
                      <>
                        <Typography variant="subtitle" className="textProduct">
                          {i.userEmail}
                        </Typography>
                      </>
                    }
                  >
                    <Card
                      elevation={0}
                      variant="outlined"
                      sx={{
                        position: "relative",
                        px: 0.5,
                        py: 1,
                        height: "100%",
                        width: "100%",
                        maxWidth: "100%",
                        textAlign: "center",
                        backgroundColor: "primary.lighter",
                        ...(isLastItem && {
                          borderColor: "success.main",
                          backgroundColor: "success.lighter",
                          borderWidth: 2,
                        }),
                      }}
                    >
                      <Stack alignItems={"center"} spacing={1}>
                        {isLastItem && (
                          <Tooltip title="Finisher">
                            <CheckCircle
                              fontSize="small"
                              sx={{ color: "success.main", position: "absolute", zIndex: 9, top: 2, right: 2 }}
                            />
                          </Tooltip>
                        )}
                        <Avatar className="avatar" alt={i.name} src="/upload/images/avatar-1.jpg" />
                        <Typography
                          variant="subtitle2"
                          sx={{
                            maxWidth: "100%",
                          }}
                          className="textProduct mt-1"
                        >
                          {i.name}
                        </Typography>
                        {row.startDate && (
                          <Typography variant="body2" className="textProduct">
                            {addPeriod({
                              period: row.period,
                              date: row.startDate,
                              periodInex: index,
                            }).format("DD/MM/YYYY")}
                          </Typography>
                        )}
                      </Stack>
                    </Card>
                  </Tooltip>
                );
              }
              return (
                <Grid
                  item
                  key={i.lpID}
                  xs
                  maxWidth={"200px"}
                  sx={{
                    maxWidth: "200px",
                  }}
                >
                  {renderItem}
                </Grid>
              );
            })}
            {Array(MAX_ROWS - row.listProgress.length)
              .fill(0)
              .map((_, index) => (
                <Grid
                  item
                  key={index}
                  xs
                  maxWidth={"200px"}
                  sx={{
                    maxWidth: "200px",
                  }}
                >
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      bgcolor: "grey.300",
                    }}
                    elevation={0}
                  ></Card>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const InfoItem = ({ icon, label, value }) => {
  const theme = useTheme();
  return (
    <Stack
      component={Card}
      sx={{ px: 2, py: 1, width: "100%", backgroundColor: theme.palette.grey[100] }}
      elevation={0}
      direction={"row"}
      gap={2}
    >
      {icon}
      <Stack>
        <Typography variant="subtitle2" color={"GrayText"}>
          {label}
        </Typography>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};
export { LineRow };
