import { Dialog, DialogActions, DialogTitle, DialogContent, Button } from "@mui/material";

import { useState } from "react";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import UploadMultiFile from "./UploadMultiFile";
import { useCallback } from "react";
import { LoadingButton } from "@mui/lab";
import { uploadFile, uploadFiles } from "../../api/file";
import { UploadFile } from "@mui/icons-material";

const UploadMultipleFileDialog = ({ open, onClose, onSave, onUpload, shouldPreview = true }) => {
  const [value, setValue] = useState([]);
  const hasFile = value && value.length > 0;
  const [uploadFinished, setUploadFinished] = useState(false);

  const [isLoadingUpfile, setIsLoadingUpfile] = useState(false);
  const [paths, setPaths] = useState([]);

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const olds = value ?? [];
      setIsLoadingUpfile(true);
      // const data = await saveFilesToServer(acceptedFiles);
      // uploadFiles({ files: acceptedFiles });
      console.log(acceptedFiles);
      if (shouldPreview) {
        // acceptedFiles = acceptedFiles.map((file) => {
        //   return { ...file, preview: URL.createObjectURL(file) };
        // });
        acceptedFiles = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }

      setValue([...olds, ...acceptedFiles]);
      setIsLoadingUpfile(false);
    },
    [setValue]
  );

  const handleRemoveAll = () => {
    setValue([]);
  };

  const handleRemove = (file) => {
    const filteredItems = value.filter((_file) => _file.path !== file.path);
    setValue(filteredItems);
  };

  const handleUpload = async () => {
    setIsLoadingUpfile(true);
    try {
      var res = await uploadFiles({ files: value });
    } catch (error) {}
    setIsLoadingUpfile(false);
    setUploadFinished(true);

    console.log("res", res);
    setPaths(res.data);
  };

  const handleSave = async () => {
    await onSave(paths);
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>
        Tải file
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <UploadMultiFile
          maxSize={31457280}
          accept="*"
          files={value}
          onDrop={handleDrop}
          onRemove={handleRemove}
          onRemoveAll={handleRemoveAll}
          //   error={Boolean(error)}
          showPreview={shouldPreview}
          hideRemoveAll={true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Đóng
        </Button>
        {hasFile && !uploadFinished && (
          <LoadingButton loading={isLoadingUpfile} onClick={handleUpload} variant="contained">
            Upload
          </LoadingButton>
        )}
        {uploadFinished && <Button onClick={handleSave}>Save</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default UploadMultipleFileDialog;
